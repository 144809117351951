<template>
  <div class="mb-2">
    <b-form-group style="width: 20%">
      <b-form-input
        v-model="filter.search"
        type="search"
        placeholder="Search"
        @input="searchCons"
      />
    </b-form-group>

    <b-card
      class="mt-2"
    >
      <b-row>
        <b-col md="3">
          <b-form-group label="Стадия">
            <v-select
              disabled
              placeholder="Выберите Стадия"
            />
          </b-form-group>
        </b-col>

        <b-col md="3">
          <b-form-group label="Стадия ликвидации">
            <v-select
              disabled
              placeholder="Выберите Стадия ликвидации"
            />
          </b-form-group>
        </b-col>

        <b-col md="3">
          <b-form-group label="Стадия банкротства">
            <v-select
              disabled
              placeholder="Выберите Стадия банкротства"
            />
          </b-form-group>
        </b-col>

        <b-col md="3">
          <b-form-group label="Приоритет">
            <v-select
              disabled
              placeholder="Выберите Приоритет"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-collapse
        id="collapse-filter"
        v-model="showAllFilters"
      >
        <b-row>
          <b-col md="3">
            <b-form-group label="Взыскатель">
              <v-select placeholder="Выберите Взыскатель" />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Статус компании">
              <v-select placeholder="Выберите Статус компании" />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Сумма “С” - “По”">
              <b-row>
                <b-col md="6">
                  <b-form-input placeholder="Сумма “С”" />
                </b-col>
                <b-col md="6">
                  <b-form-input placeholder="Сумма “По”" />
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Выбор ООО/ИП">
              <v-select placeholder="Выберите ООО/ИП" />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Кейс">
              <v-select placeholder="Выберите Кейс" />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Наличие платежей">
              <v-select placeholder="Выберите Наличие платежей" />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Принадлежность">
              <v-select placeholder="Выберите Принадлежность" />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Ответственный менеджер">
              <v-select placeholder="Выберите Ответственный менеджер" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group label="Платежи “С” - “По”">
              <VcDatePicker
                ref="birthdayRef"
                show-format="DD.MM.YYYY"
                :other-props="{ 'is-range': true }"
                placeholder="DD.MM.YYYY"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Последний платеж “С” - “По”`">
              <VcDatePicker
                ref="birthdayRef"
                show-format="DD.MM.YYYY"
                :other-props="{ 'is-range': true }"
                placeholder="DD.MM.YYYY"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            v-for="(tag, index) in tags"
            :key="tag.unique_name"
            md="6"
          >
            <div class="d-flex justify-content-between w-100">
              <label
                class="p-style"
                :for="'checkbox_' + index"
              >{{ tag.name }}</label>
              <b-form-checkbox
                :id="'checkbox_' + index"
                v-model="tag.isSelected"
                class="custom-control-primary"
              />
            </div>
          </b-col>
        </b-row>
      </b-collapse>
      <b-button
        disabled
        variant="info"
        class="w-100 mt-2"
        @click="toggleFilters"
      >
        {{ showAllFilters ? 'Показать меньше фильтров' : 'Показать больше фильтров' }}
      </b-button>
      <div class="d-flex justify-content-end mt-2">
        <b-button
          disabled
          variant="outline-primary mr-1"
          @click="$emit('clearFilter')"
        >
          Сбросить фильтр
        </b-button>
        <b-button
          disabled
          variant="success"
        >
          Скачать в Excel
        </b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BCollapse,
  BFormCheckbox,
} from 'bootstrap-vue'
import VSelect from 'vue-select'

export default {
  components: {
    BButton,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BCollapse,
    VSelect,
    BFormCheckbox,
  },
  props: {
    filter: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      params: {},
      tags: [
        {
          name: 'Наличие письменного заявления об отказе от взаимодействия:',
          unique_name: '001',
          isSelected: true,
        },
        {
          name: 'Включен в реестр требований кредитора (ртк):',
          unique_name: '002',
          isSelected: true,
        },
        {
          name: 'Правопреемство:',
          unique_name: '003',
          isSelected: true,
        },
        {
          name: 'Просужен:',
          unique_name: '004',
          isSelected: true,
        },
        {
          name: 'Имущество:',
          unique_name: '005',
          isSelected: true,
        },
        {
          name: 'Движения по счетам:',
          unique_name: '006',
          isSelected: true,
        },
        {
          name: 'Субсидиарная ответственность:',
          unique_name: '007',
          isSelected: true,
        },
        {
          name: 'VIP-должник:',
          unique_name: '008',
          isSelected: true,
        },
      ],
      showAllFilters: false,
    }
  },
  methods: {
    toggleFilters() {
      this.showAllFilters = !this.showAllFilters
    },

    searchCons() {
      this.$emit('searchCons')
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.p-style {
  line-height: 1.5rem;
  margin-bottom: 1rem;
  font-size: 1rem;
}
</style>
