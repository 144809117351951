<template>
  <b-overlay
    :show="reFetch"
    rounded="sm"
  >
    <BTable
      class="table__pass"
      :busy="reFetch"
      :items="skLegal.results"
      :fields="fields"
      show-empty
      responsive
    >
      <template #empty>
        <empty />
      </template>

      <template #cell(EMAIL)="props">
        <div
          v-for="(item, index) in props.item.EMAIL"
          :key="index"
          @click.stop
        >
          <a
            :href="'mailto:' + item"
            class="ml-50 text-right"
            v-html="item"
          />
        </div>
      </template>

      <template #cell(FULL_NAME)="data">
        <router-link
          v-if="data.item.FULL_NAME"
          :to="`/sk-entity/${data.item.id}`"
        >
          {{ data.item.FULL_NAME }}
        </router-link>
      </template>
    </BTable>
  </b-overlay>
</template>
<script>
import { mapState } from 'vuex'
import {
  BTable, VBTooltip, BOverlay,
} from 'bootstrap-vue'
import empty from '@/components/empty.vue'
import FIELDS from '../constants/tableFields'

export default {
  components: {
    BOverlay,
    BTable,
    empty,
  },
  props: {
    reFetch: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      fields: FIELDS.fields,
    }
  },
  computed: {
    ...mapState('entity', ['skLegal']),
  },
  mounted() {
    // this.FETCH_ALL_USERS({ page_size: 500 })
  },
  methods: {},
  // eslint-disable-next-line vue/order-in-components
  directives: {
    'b-tooltip': VBTooltip,
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.w_g {
  width: 250px
}

.table__pass td {
  vertical-align: top;
}
</style>
