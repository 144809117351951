export default {
  fields: [
    {
      key: 'id',
      label: '№',
      sortable: false,
    },
    {
      key: 'FULL_NAME',
      label: 'Названия',
      sortable: false,
    },
    {
      key: 'EMAIL',
      label: 'Электронная почта',
      sortable: false,
    },
  ],
}
