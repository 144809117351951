<template>
  <div>
    <EntityFilters
      :filter="filter"
      @refresh="refresh"
      @clearFilter="clearFilter"
      @searchCons="searchCons"
    />

    <b-card>
      <TheTable :re-fetch="reFetch" />
    </b-card>

    <b-card>
      <div class="d-flex justify-content-between flex-wrap">
        <div class="d-flex align-items-center mb-0">
          <span class="text-nowrap mr-1">Показать по</span>
          <b-form-select
            v-model="filter.page_size"
            :options="['5', '10', '20']"
            class="mx-1"
            @change="changePerPage"
          />
          <span class="ml-1 text-nowrap"> строк( {{ skLegal.count }} )</span>
        </div>
        <div>
          <b-pagination
            v-model="filter.page"
            :total-rows="skLegal.count"
            :per-page="filter.page_size"
            first-number
            last-number
            align="right"
            prev-class="prev-item"
            next-class="next-item"
            class="mt-1 mb-0"
            @change="changePage"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import {
  BPagination,
  BCard,
  BFormSelect,
} from 'bootstrap-vue'
import TheTable from '../components/EntityTable.vue'
import EntityFilters from '../components/EntityFilters.vue'

export default {
  components: {
    BPagination,
    BCard,
    BFormSelect,
    EntityFilters,
    TheTable,
  },

  data() {
    return {
      reFetch: false,
      filter: {
        search: null,
        page: 1,
        page_size: 20,
      },
    }
  },

  computed: {
    ...mapState('entity', ['skLegal']),
  },

  mounted() {
    this.refresh()
  },

  methods: {
    ...mapActions('entity', ['FETCH_SK_LEGAL']),

    searchCons() {
      clearTimeout(this.time)
      this.filter.page = 1
      this.time = setTimeout(() => {
        this.refresh()
      }, 400)
    },

    async changePerPage(pageSize) {
      try {
        this.reFetch = true
        this.filter.page_size = pageSize
        this.filter.page = 1
        await this.FETCH_SK_LEGAL(this.filter)
      } catch (error) {
        await this.$_errorToast()
      }
      this.reFetch = false
    },

    async changePage(value) {
      try {
        this.reFetch = true
        this.filter.page = value
        await this.FETCH_SK_LEGAL(this.filter)
      } catch (e) {
        await this.$_errorToast(e)
      }
      this.reFetch = false
    },

    async refresh() {
      // const sessionFilter = this.$_getFilter()
      //
      // if (sessionFilter) {
      //   this.filter = { ...sessionFilter }
      //
      //   this.setFilterMy()
      // }

      try {
        this.reFetch = true
        await this.FETCH_SK_LEGAL(this.filter)
      } catch (error) {
        await this.$_errorToast()
      }
      this.reFetch = false
    },

    clearFilter() {
      this.filter = {
        search: null,
        page: 1,
        page_size: 20,
      }
      this.FETCH_SK_LEGAL(this.filter)
    },
  },
}
</script>

<style lang="scss" ></style>
